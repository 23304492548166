<script setup lang="ts">
// State
const open: Ref<boolean> = ref(false);
const { t, locale } = useI18n();

// Methods
const setLocale = (newLocale: string) => {
  open.value = false;
  localStorage.setItem('locale', newLocale);
};

// Watchers
watch(locale, setLocale);
</script>

<template>
  <!-- Switcher -->
  <div
    @click="open = true"
    class="flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-full border-[3px] border-black"
  >
    <img
      src="/images/flags/nl-NL.svg"
      alt="nl-NL"
      v-if="locale === 'nl'"
      class="h-full w-full"
    />
    <img
      src="/images/flags/en-GB.svg"
      alt="en-GB"
      v-else-if="locale === 'en'"
      class="h-full w-full"
    />
  </div>

  <!-- Dialog -->
  <modal :open="open" @closed="open = false">
    <HeadlessDialogTitle as="h3" class="text-center font-bold text-[#300E65]">
      {{ t('language-switcher.title') }}
    </HeadlessDialogTitle>

    <ul class="languages">
      <li class="languages__language">
        <input
          type="radio"
          v-model="locale"
          value="nl"
          id="ls-dutch"
          class="hidden"
        />
        <label for="ls-dutch">
          Nederlands

          <img src="/images/flags/nl-NL.svg" class="h-6" />
        </label>
      </li>
      <li class="languages__language">
        <input
          type="radio"
          v-model="locale"
          value="en"
          id="ls-english"
          class="hidden"
        />
        <label for="ls-english">
          English

          <img src="/images/flags/en-GB.svg" class="h-6" />
        </label>
      </li>
    </ul>

    <div class="flex justify-center">
      <Button color="red" class="!px-12" @click="open = false">
        {{ t('language-switcher.ok') }}
      </Button>
    </div>
  </modal>
</template>

<style lang="scss" scoped>
.languages {
  @apply space-y-2 pb-4;

  &__language {
    label {
      @apply flex justify-between px-3 py-1;
    }
    input:checked + label {
      @apply bg-gray-200 font-bold text-red-500;
    }
  }
}
</style>
